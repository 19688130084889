import Head from "next/head";
import Router, { useRouter } from "next/router";
import ProgressBar from "@badrap/bar-of-progress";
import { SessionProvider } from "next-auth/react";
import { GoogleAnalytics } from "nextjs-google-analytics-gtm";
import Toast from "@/components/Toast";
import "@/styles/main.scss";
import { Toaster } from "react-hot-toast";
import Script from "next/script";

const progress = new ProgressBar({
  size: 2,
  color: "#2363EB",
  className: "bar-of-progress",
  delay: 100,
});

if (typeof window !== "undefined") {
  progress.start();
  progress.finish();
}

Router.events.on("routeChangeStart", progress.start);
Router.events.on("routeChangeComplete", () => {
  progress.finish();
  window.scrollTo(0, 0);
});
Router.events.on("routeChangeError", progress.finish);

export default function App({ Component, pageProps }: any) {
   const router = useRouter();
   const isExcluded = router.pathname.startsWith("/[id]");
  return (
    <>
      <Head>
        {!isExcluded&&<>
        <GoogleAnalytics />
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-R0QPWVG6LX"
          ></script>
        <script
          dangerouslySetInnerHTML={{
            __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            
            gtag('config', 'G-R0QPWVG6LX');
            `,
          }}
          ></script>
        <script
          dangerouslySetInnerHTML={{
            __html: `
          (function(c,l,a,r,i,t,y){
            c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
            t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
            y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
            })(window, document, "clarity", "script", "lty1y3p17h");`,
          }}
          ></script>
          </>}

        <meta
          name="viewport"
          content="width=device-width, initial-scale=1"
        ></meta>
        <title>Tradewise</title>
      </Head>
      <Toast />
      <SessionProvider>
        <Component {...pageProps} />
        <Toaster position="top-center" />
      </SessionProvider>
    </>
  );
}
